import React from 'react';
import { Link } from 'react-router-dom';

function Kurzy() {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center', padding: '24px' }}>
      <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#244075', marginBottom: '32px' }}>Kurzy</h1>
      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Link 
          to="/kurzy/zakladni-skola" 
          style={{
            display: 'block',
            backgroundColor: '#244075',
            color: '#fff',
            padding: '24px',
            fontSize: '1.25rem',
            fontWeight: '600',
            textAlign: 'center',
            borderRadius: '8px',
            textDecoration: 'none',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#1a2f5c'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#244075'}
        >
          Základní škola
        </Link>

        <Link 
          to="/kurzy/stredni-skola" 
          style={{
            display: 'block',
            backgroundColor: '#244075',
            color: '#fff',
            padding: '24px',
            fontSize: '1.25rem',
            fontWeight: '600',
            textAlign: 'center',
            borderRadius: '8px',
            textDecoration: 'none',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#1a2f5c'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#244075'}
        >
          Střední škola
        </Link>
        
        <Link 
          to="/kurzy/vysoka-skola" 
          style={{
            display: 'block',
            backgroundColor: '#244075',
            color: '#fff',
            padding: '24px',
            fontSize: '1.25rem',
            fontWeight: '600',
            textAlign: 'center',
            borderRadius: '8px',
            textDecoration: 'none',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#1a2f5c'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#244075'}
        >
          Vysoká škola
        </Link>
      </div>
    </div>
  );
}

export default Kurzy;
