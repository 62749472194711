import React from 'react';

function Pricing() {
  return (
    <div className="pricing-container" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h2 style={{ color: '#244075', textAlign: 'center', marginBottom: '20px' }}>Ceník doučování</h2>
      
      <div className="pricing-info" style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
        <h3 style={{ color: '#244075' }}>Základní ceny:</h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ marginBottom: '10px' }}>
            <strong>Běžná cena:</strong> 650 Kč za 60 minut
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Jednorázová lekce:</strong> 800 Kč za 60 minut
          </li>
        </ul>
      </div>

      <div className="pricing-details" style={{ marginBottom: '20px' }}>
        <h3 style={{ color: '#244075' }}>Podrobnosti k cenám:</h3>
        <ul>
          <li>Při zakoupení více lekcí najednou je cena 650 Kč za 60 minut.</li>
          <li>Pro pravidelnou spolupráci je možné cenu mírně snížit. Konkrétní podmínky budou stanoveny na základě vzájemné domluvy.</li>
          <li>Cena zahrnuje přípravu na lekci a případné dodatečné materiály.</li>
        </ul>
      </div>

      <div className="pricing-note" style={{ backgroundColor: '#e6f2ff', padding: '15px', borderRadius: '8px' }}>
        <p style={{ margin: 0 }}><strong>Poznámka:</strong> Konečná cena může být upravena podle specifických potřeb studenta, frekvence doučování a dalších faktorů. Pro přesnou kalkulaci mě prosím kontaktujte.</p>
      </div>
    </div>
  );
}

export default Pricing;