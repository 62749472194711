import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CourseSection = ({ title, description, topics, isOpen, toggle }) => {
  return (
    <div className="mb-4">
      <button className="accordion-button" onClick={toggle}>
        {title} {isOpen ? '▲' : '▼'}
      </button>
      {isOpen && (
        <div className="accordion-content">
          <p className="mb-4">{description}</p>
          {topics && (
            <ul className="list-disc pl-5">
              {topics.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

const Courses = () => {
  const navigate = useNavigate();
  const [mathOpen, setMathOpen] = useState(true);
  const [statsOpen, setStatsOpen] = useState(false);

  const mathTopics = [
    "Množiny a výroky",
    "Posloupnosti reálných čísel",
    "Limity a spojitost funkcí",
    "Diferenciální počet",
    "Integrální počet",
    "Funkce více proměnných",
    "Diferenciální rovnice",
    "Lineární algebra",
    "Maticový počet",
    "Taylorovy polynomy",
  ];

  return (
    <div className="container mx-auto p-6 relative" style={{ position: 'relative' }}>
      <button
        onClick={() => navigate('/kurzy')}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          backgroundColor: '#BDA37F',
          color: 'white',
          padding: '10px 20px',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          textTransform: 'uppercase',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#A8936F')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#BDA37F')}
      >
        Zpět
      </button>

      <h1 className="text-4xl font-bold mb-8 text-[#244075]">Vysoká škola</h1>

      <CourseSection
        title="Matematika"
        description="Pomáhám s širokou škálou matematických disciplín, od základů až po pokročilé koncepty. Moje výuka zahrnuje matematickou analýzu, kalkulus, lineární algebru a více:"
        topics={mathTopics}
        isOpen={mathOpen}
        toggle={() => setMathOpen(!mathOpen)}
      />

      <CourseSection
        title="Statistika (Všechny úrovně)"
        description="Ve statistice nabízím kompletní podporu, která zahrnuje vše od základní popisné statistiky až po pokročilé metody, jako je inferenční statistika, testování hypotéz, regresní analýza a simulace Monte Carlo. Výuku přizpůsobím vaší úrovni, abyste dosáhli skvělých výsledků u zkoušek."
        isOpen={statsOpen}
        toggle={() => setStatsOpen(!statsOpen)}
      />

      <div className="mt-8 text-center">
        <p className="text-xl mb-4 text-[#244075] mt-4">Připraven začít?</p>
        <a href="/reserve" className="reserve-button">
          Rezervovat doučování
        </a>
      </div>
    </div>
  );
};

export default Courses;


