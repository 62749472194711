import React from 'react';
import './SharedStyles.css';

function Reserve() {
  return (
    <div className="reserve-container" style={{ maxWidth: '600px', margin: '0 auto', padding: '20px 20px 100px' }}>
      <h1 style={{ color: '#244075', textAlign: 'center', marginBottom: '30px' }}>Rezervujte si doučovací hodinu</h1>
      
      <div className="contact-section" style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', marginBottom: '30px' }}>
        <p style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '15px' }}>
          Pro rezervaci hodiny mě neváhejte kontaktovat přímo:
        </p>
        <ul>
          <li style={{ marginBottom: '10px' }}>
            <strong>Telefon:</strong> <a href="tel:+420777863536">+420 777 863 536</a>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>WhatsApp:</strong> <a href="https://wa.me/420777863536">+420 777 863 536</a>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <strong>Email:</strong> <a href="mailto:uztochapu@gmail.com">uztochapu@gmail.com</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Reserve;
