import React from 'react';
import './SharedStyles.css'; // Make sure this path is correct

function About() {
  return (
    <div className="about-container" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px 20px 100px' }}>
      <h1 style={{ color: '#244075', textAlign: 'center', marginBottom: '30px' }}>Kdo jsem?</h1>
      
      <div className="intro-section" style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', marginBottom: '30px' }}>
        <h2 style={{ color: '#244075', marginBottom: '15px' }}>Ahoj, já jsem Týna! 👋</h2>
        <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
          Je fér, abych ti na začátek řekla něco o sobě, abys věděl/a do čeho jdeš. Jsem mladá, vtipná holka, kterou baví:
        </p>
        <ul style={{ fontSize: '18px', lineHeight: '1.6', marginLeft: '20px' }}>
          <li>Matematika 🧮</li>
          <li>Sport 🏃‍♀️</li>
          <li>Vidět lidi, jak jsou happy, když konečně něco pochopí 😊</li>
        </ul>
        <p style={{ fontSize: '18px', lineHeight: '1.6', marginTop: '15px' }}>
          Proto jsem se rozhodla doučovat a pomáhat studentům jako ty!
        </p>
      </div>

      <div className="education-section" style={{ marginBottom: '30px' }}>
        <h2 style={{ color: '#244075', marginBottom: '15px' }}>Moje vzdělání a zkušenosti 🎓</h2>
        <ul style={{ fontSize: '18px', lineHeight: '1.6', marginLeft: '20px' }}>
          <li>Bc. Ekonomie a Finance - Univerzita Karlova</li>
          <li>MSc in Statistical Science - University of Oxford</li>
          <li>Výměnné pobyty:
            <ul>
              <li>Australian National University</li>
              <li>University of Sheffield</li>
            </ul>
          </li>
          <li>Zkušenosti s trénováním dětí - velká trpělivost zaručena! 😉</li>
        </ul>
      </div>

      <div className="cta-section" style={{ textAlign: 'center', marginTop: '40px' }}>
        <h2 style={{ color: '#244075', marginBottom: '20px' }}>Jseš ready začít?</h2>
        <a href="/reserve" className="cta-button" style={{
          backgroundColor: '#244075',
          color: 'white',
          padding: '15px 30px',
          fontSize: '20px',
          textDecoration: 'none',
          borderRadius: '5px',
          display: 'inline-block',
          transition: 'background-color 0.3s'
        }}>
          Rezervuj si doučko hned teď!
        </a>
      </div>
    </div>
  );
}

export default About;