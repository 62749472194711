import React from 'react';

const Availability = () => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const days = ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'];
  
  const availability = {
    "Po": { "atLocation": [7,8,17,18], "online": [7,8,17,18] },
    "Út": { "atLocation": [7,8,17,18,19], "online": [7,8,17,18,19, 20, 21] },
    "St": { "atLocation": [7,8,17,18,19], "online": [7,8,17,18,19, 20, 21] },
    "Čt": { "atLocation": [7,8,17,18,19], "online": [7,8,17,18,19] },
    "Pá": { "atLocation": [7,8], "online": [7,8,17,18] },
    "So": { "atLocation": [], "online": [7,8,9,10, 17, 18, 19, 20, 21] },
    "Ne": { "atLocation": [], "online": [7,8,9,10, 11, 12] }
  };

  const getAvailabilityClass = (day, hour) => {
    const isAtLocation = availability[day].atLocation.includes(hour);
    const isOnline = availability[day].online.includes(hour);
    
    if (isAtLocation && isOnline) return 'both';
    if (isAtLocation) return 'at-location';
    if (isOnline) return 'online';
    return '';
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto 100px', padding: '20px' }}>
      <h2 style={{ textAlign: 'center' }}>Dostupnost v běžném týdnu</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto repeat(7, 1fr)', gap: '1px', backgroundColor: '#ccc' }}>
        <div style={{ padding: '5px', fontWeight: 'bold', backgroundColor: 'white' }}>Hodina</div>
        {days.map(day => (
          <div key={day} style={{ padding: '5px', textAlign: 'center', fontWeight: 'bold', backgroundColor: 'white' }}>{day}</div>
        ))}
        {hours.map(hour => (
          <React.Fragment key={hour}>
            <div style={{ padding: '5px', textAlign: 'right', backgroundColor: 'white', borderBottom: '1px solid #ccc' }}>
              {hour}:00 - {(hour + 1) % 24}:00
            </div>
            {days.map(day => (
              <div
                key={`${day}-${hour}`}
                style={{
                  height: '30px',
                  backgroundColor: getAvailabilityClass(day, hour) ? 'white' : '#f0f0f0',
                  display: 'flex',
                  borderBottom: '1px solid #ccc'
                }}
              >
                {getAvailabilityClass(day, hour) === 'both' ? (
                  <>
                    <div style={{ flex: 1, backgroundColor: '#8bc34a' }}></div>
                    <div style={{ flex: 1, backgroundColor: '#4caf50' }}></div>
                  </>
                ) : (
                  <div
                    style={{
                      flex: 1,
                      backgroundColor:
                        getAvailabilityClass(day, hour) === 'at-location' ? '#8bc34a' :
                        getAvailabilityClass(day, hour) === 'online' ? '#4caf50' : 'transparent'
                    }}
                  ></div>
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div style={{ marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: '#8bc34a', marginRight: '10px' }}></span>
          <span>Osobně, např. v kavárně (od 650 CZK)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: '#4caf50', marginRight: '10px' }}></span>
          <span>Online přes webcam (od 650 CZK)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <span style={{ display: 'inline-block', width: '40px', height: '20px', marginRight: '10px' }}>
            <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: '#8bc34a', float: 'left' }}></span>
            <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: '#4caf50', float: 'right' }}></span>
          </span>
          <span>Obě možnosti dostupné</span>
        </div>
      </div>
    </div>
  );
};

export default Availability;