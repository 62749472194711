import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Kurzy from './components/Courses';
import HighSchool from './components/HighSchool';
import PrimarySchool from './components/PrimarySchool';
import University from './components/University';
import Reserve from './components/Reserve';
import Pricing from './components/Pricing';
import Availability from './components/Availability';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="logo">
            <Link to="/home">
              <img src="/images/uztochapu-logo.svg" alt="UžToChápu Logo" />
            </Link>
          </div>
        </header>
        <nav className="App-nav">
          <ul>
            <li><Link to="/home">Domů</Link></li>
            <li><Link to="/about">O mně</Link></li>
            <li><Link to="/kurzy">Kurzy</Link></li>
            <li><Link to="/reserve">Rezervace</Link></li>
            <li><Link to="/pricing">Ceník</Link></li>
            <li><Link to="/availability">Dostupnost</Link></li>
          </ul>
        </nav>
        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/kurzy" element={<Kurzy />} />
            <Route path="/kurzy/zakladni-skola" element={<PrimarySchool />} />
            <Route path="/kurzy/stredni-skola" element={<HighSchool />} />
            <Route path="/kurzy/vysoka-skola" element={<University />} />
            <Route path="/reserve" element={<Reserve />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/availability" element={<Availability />} />
          </Routes>
        </main>
        <footer className="App-footer">
          <p>© 2024 UžToChápu. Všechna práva vyhrazena.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;