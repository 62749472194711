import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Section = ({ title, content, isOpen, toggle }) => {
  return (
    <div className="mb-4">
      <button className="accordion-button" onClick={toggle}>
        {title} {isOpen ? '▲' : '▼'}
      </button>
      {isOpen && (
        <div className="accordion-content">
          {content}
        </div>
      )}
    </div>
  );
};

const HighSchool = () => {
  const navigate = useNavigate();
  const [maturitaOpen, setMaturitaOpen] = useState(true);
  const [generalOpen, setGeneralOpen] = useState(false);

  return (
    <div className="container mx-auto p-6 relative" style={{ position: 'relative' }}>
      <button
        onClick={() => navigate('/kurzy')}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          backgroundColor: '#BDA37F',
          color: 'white',
          padding: '10px 20px',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          textTransform: 'uppercase',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#A8936F')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#BDA37F')}
      >
        Zpět
      </button>

      <h1 className="text-4xl font-bold mb-8 text-[#244075]">Střední škola</h1>

      <Section
        title="Příprava na maturitu"
        content={
          <div>
            <h2 className="text-2xl mb-4">Příprava na státní maturitu z matematiky</h2>
            <p>
              Nabízím individuální doučování pro studenty, kteří se připravují na státní maturitu 
              z matematiky. Společně se zaměříme na porozumění klíčovým tématům, procvičení 
              konkrétních typů úloh a efektivní řešení testových příkladů. Lekce jsou přizpůsobeny 
              úrovni a potřebám každého studenta.
            </p>
            <h3 className="text-xl mt-4">Jak probíhá doučování?</h3>
            <ul className="list-disc ml-6">
              <li>Detailní vysvětlení teorie a příkladů.</li>
              <li>Procvičování konkrétních úloh z didaktických testů.</li>
              <li>Tipy na efektivní postupy řešení a zvládnutí časového tlaku.</li>
              <li>Přizpůsobení výuky na základě zpětné vazby a pokroku studenta.</li>
            </ul>
            <h3 className="text-xl mt-4">Požadavky na maturitu</h3>
            <p>
              Státní maturita pokrývá témata jako algebraické výrazy, rovnice, funkce, geometrie, 
              posloupnosti a finanční matematika. Pro rozšířenou úroveň jsou navíc zahrnuty 
              složitější úlohy z analytické geometrie, goniometrie a pravděpodobnosti. Pomohu vám 
              s procvičením všech oblastí požadovaných v didaktickém testu CERMAT.
            </p>
            <h3 className="text-xl mt-4">Rozšířená maturita z matematiky</h3>
            <p>
              Rozšířená maturita není povinná, ale její úspěšné zvládnutí vám může otevřít dveře na vybrané vysoké školy. Je určena studentům, kteří chtějí prokázat hlubší znalosti a porozumění 
              pokročilým matematickým konceptům. Tato úroveň zkoušky zahrnuje komplexnější témata, 
              jako jsou goniometrické rovnice, analytická geometrie v prostoru, kombinatorika a pokročilé 
              funkce. Příprava se zaměřuje na rozvoj analytického myšlení a schopnost řešit náročnější 
              úlohy, které vyžadují důkladné pochopení teorie a aplikaci efektivních postupů.
            </p>
          </div>
        }
        isOpen={maturitaOpen}
        toggle={() => setMaturitaOpen(!maturitaOpen)}
      />

      <Section
        title="Průběžné doučování"
        content={
          <p>
            Nabízím pravidelné doučování matematiky během celého školního roku, zaměřené na
            průběžné procvičování látky, vysvětlení nejasností a přípravu na školní testy. Výuka je
            přizpůsobena individuálním potřebám studentů, aby mohli snadno zvládnout všechny
            matematické výzvy.
          </p>
        }
        isOpen={generalOpen}
        toggle={() => setGeneralOpen(!generalOpen)}
      />

      <div className="mt-8 text-center">
        <p className="text-xl mb-4 text-[#244075] mt-4">Začněte s přípravou včas!</p>
        <a href="/reserve" className="reserve-button">
          Rezervovat doučování
        </a>
      </div>
    </div>
  );
};

export default HighSchool;