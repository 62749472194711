import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Section = ({ title, content, isOpen, toggle }) => {
  return (
    <div className="mb-4">
      <button className="accordion-button" onClick={toggle}>
        {title} {isOpen ? '▲' : '▼'}
      </button>
      {isOpen && (
        <div className="accordion-content">
          {content}
        </div>
      )}
    </div>
  );
};

const PrimarySchool = () => {
  const navigate = useNavigate();
  const [admissionsOpen, setAdmissionsOpen] = useState(true);
  const [generalTutoringOpen, setGeneralTutoringOpen] = useState(false);

  return (
    <div className="container mx-auto p-6 relative" style={{ position: 'relative' }}>
      <button
        onClick={() => navigate('/kurzy')}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          backgroundColor: '#BDA37F',
          color: 'white',
          padding: '10px 20px',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          textTransform: 'uppercase',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#A8936F')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#BDA37F')}
      >
        Zpět
      </button>

      <h1 className="text-4xl font-bold mb-8 text-[#244075]">Základní škola</h1>

      <Section
        title="Příprava na přijímací zkoušky"
        content={
          <div className="space-y-6">
            <h2 className="text-2xl mb-4">Příprava na přijímací zkoušky</h2>
            <p>
              Nabízím cílenou přípravu na přijímací zkoušky z matematiky, přizpůsobenou konkrétní třídě a typu školy, na kterou se student hlásí. Každý program je přizpůsoben úrovni žáka, se zaměřením na posílení matematických dovedností a efektivní zvládnutí testových úloh.
            </p>

            <h3 className="text-xl font-semibold">5. třída (osmiletá gymnázia)</h3>
            <ul className="list-disc ml-6 space-y-2">
                <li>Základní matematické operace a logické myšlení</li>
                <li>Geometrie a prostorová představivost</li>
                <li>Řešení slovních úloh a práce s grafy</li>
                <li>Nácvik efektivního řešení testových úloh</li>
            </ul>

            <h3 className="text-xl font-semibold">7. třída (šestiletá gymnázia)</h3>
            <ul className="list-disc ml-6 space-y-2">
                <li>Rovnice a jejich úpravy</li>
                <li>Procenta a poměry</li>
                <li>Geometrické konstrukce</li>
                <li>Práce s funkcemi a grafy</li>
            </ul>

            <h3 className="text-xl font-semibold">9. třída (všechny střední školy)</h3>
            <ul className="list-disc ml-6 space-y-2">
            <li>Algebraické výrazy a jejich úpravy</li>
                <li>Rovnice a nerovnice</li>
                <li>Funkce a jejich vlastnosti</li>
                <li>Planimetrie a stereometrie</li>
                <li>Příprava na specifické požadavky vybrané školy</li>
            </ul>
          </div>
        }
        isOpen={admissionsOpen}
        toggle={() => setAdmissionsOpen(!admissionsOpen)}
      />

      <Section
        title="Průběžné doučování"
        content={
          <p>
            Nabízím pravidelné doučování matematiky během celého školního roku, zaměřené na
            průběžné procvičování látky, vysvětlení nejasností a přípravu na školní testy. Výuka je
            přizpůsobena individuálním potřebám žáků, aby mohli snadno zvládnout všechny
            matematické výzvy.
          </p>
        }
        isOpen={generalTutoringOpen}
        toggle={() => setGeneralTutoringOpen(!generalTutoringOpen)}
      />

      <div className="mt-8 text-center">
        <p className="text-xl mb-4 text-[#244075] mt-4">Začněte s přípravou včas!</p>
        <a href="/reserve" className="reserve-button">
          Rezervovat doučování
        </a>
      </div>
    </div>
  );
};

export default PrimarySchool;
