import React from 'react';
import './SharedStyles.css';

function Home() {
  return (
    <div className="home-container" style={{ 
      maxWidth: '800px', 
      margin: '0 auto', 
      padding: '20px 20px 100px', // Added significant bottom padding
      minHeight: '100vh', // Ensure it takes at least full viewport height
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <div>
        <h1 style={{ color: '#244075', textAlign: 'center', marginBottom: '30px' }}>Vítej u mě na doučku!</h1>
        
        <div className="intro-section" style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', marginBottom: '30px' }}>
        <h2 style={{ color: '#244075', marginBottom: '15px' }}>Oblasti specializace</h2>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            Doučuji už pěkně dlouho a mám to moc ráda. Specializuji se zejména na doučování:
          </p>
          <ul style={{ fontSize: '18px', lineHeight: '1.6', marginLeft: '20px' }}>
            <li>Matematika pro žáky základních a středních škol</li>
            <li>Matematika pro studenty vysokých škol</li>
            <li>Statistika a analýza dat</li>
            <li>Ekonomie, ekonometrie a finanční matematika</li>
          </ul>
        </div>

        <div className="intro-section" style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', marginBottom: '30px' }}>
          <h2 style={{ color: '#244075', marginBottom: '15px' }}>Příprava na zkoušky</h2>
          <ul style={{ fontSize: '18px', lineHeight: '1.6', marginLeft: '20px' }}>
            <li>Přijímací zkoušky na střední školy (CERMAT)</li>
            <li>Maturita z matematiky (základní i rozšířená úroveň)</li>
            <li>SCIO testy z matematiky</li>
          </ul>
        </div>


        <div className="intro-section" style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', marginBottom: '30px' }}>
          <h2 style={{ color: '#244075', marginBottom: '15px' }}>
            Komu je doučování určeno
          </h2>
          <div>
            <h3 style={{  fontSize: '20px', marginBottom: '10px' }}>Žáci základních škol</h3>
            <p style={{ fontSize: '18px', lineHeight: '1.6'}}>
              Pomáhám pochopit základy matematiky a připravuji na přijímací zkoušky na střední školy
            </p>
          </div>
          <div style={{ marginTop: '20px' }}>
            <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>Studenti středních škol</h3>
            <p style={{ fontSize: '18px', lineHeight: '1.6'}}>
              Podpora během studia, příprava na maturitu a SCIO testy
            </p>
          </div>
          <div style={{ marginTop: '20px' }}>
            <h3 style={{ fontSize: '20px', marginBottom: '10px'}}>Studenti vysokých škol</h3>
            <p style={{ fontSize: '18px', lineHeight: '1.6'}}>
              Odborná pomoc s pokročilou matematikou, statistikou a ekonometrií 
            </p>
          </div>
        </div>


        <div className="approach-section" style={{ marginBottom: '30px' }}>
          <h2 style={{ color: '#244075', marginBottom: '15px' }}>Můj přístup</h2>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            Preferuji dlouhodobější spolupráci, např. jeden semestr/pololetí nebo i víc - klíčem k úspěchu je systematická a dlouhodobá práce (ne učení se na poslední chvíli). Je to jen na tobě a tvých potřebách. Společně vytvoříme plán, který ti pomůže dosáhnout tvých studijních cílů.
          </p>
        </div>
      </div>

      <div className="cta-section" style={{ textAlign: 'center', marginTop: '40px' }}>
        <h2 style={{ color: '#244075', marginBottom: '20px' }}>Jseš ready?</h2>
        <a href="/reserve" className="cta-button" style={{
          backgroundColor: '#244075',
          color: 'white',
          padding: '15px 30px',
          fontSize: '20px',
          textDecoration: 'none',
          borderRadius: '5px',
          display: 'inline-block',
          transition: 'background-color 0.3s'
        }}>
          Rezervuj si doučko hned teď!
        </a>
      </div>
    </div>
  );
}

export default Home;